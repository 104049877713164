import { Modal } from '../../../../componentsV2/Modal';
import {
  TopAccountRecord,
  TopAccountsCondensedTable,
} from './components/TopAccountsCondensedTable';
import { useQuery } from '@tanstack/react-query';
import { coreService } from '../../../../services/core/core-service';
import { PropsWithChildren, useMemo, useState } from 'react';
import Card from '../../../../componentsV2/Card';

import Styles from './styles';
import {
  Button,
  Collapse,
  Dropdown,
  Flex,
  Pagination,
  Select,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { DashboardFilterProvider } from '../../../shared/dashboard-filters/dashboard-filters.context';
import { useDashboardFilters } from '../../../shared/dashboard-filters/dashboard-filters.hooks';
import { addDays } from 'date-fns';
import { useToast } from '../../../../hooks/useToast';
import {
  CopyOutlined,
  DatabaseOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { Alert } from '../../../../components/alerts/Alert';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux/typings/store';
import { OptionalTooltipWrapper } from '../../../../componentsV2/OptionalTooltipWrapper';

const { Container, CardContents, SummaryContainer } = Styles;

interface Props {
  open: boolean;
  topic: {
    id: string;
    name: string;
    description: string;
    following: boolean;
    custom: boolean;
  };
  filters: {
    from: string;
    to: string;
    accountId?: string;
  };
  onClose: () => void;
  onEditTopicClicked?: (args: { id: string; name: string; description: string }) => void;
  onFollowTopicClicked?: (id: string) => void;
  onUnfollowTopicClicked?: (id: string) => void;
}

export const Wrapper: React.FC<PropsWithChildren<Omit<Props, 'filters'>>> = ({
  topic,
  open,
  onClose,
  onEditTopicClicked,
  onFollowTopicClicked,
  onUnfollowTopicClicked,
  children,
}) => {
  const { filters, getAccountFilterOptions, getDateFilterOptions, render, changeAccountValue } =
    useDashboardFilters();

  const user = useSelector((store: Store) => store.session.user);
  const isOwner = user?.membership.role === 'OWNER' || user?.membership.role === 'ADMIN';

  const topAccountsFilters = useMemo(() => {
    const dateValues = getDateFilterOptions('dateRange');
    const accountValue = getAccountFilterOptions('account');

    return {
      from: dateValues[0] ? dateValues[0].toISOString() : addDays(new Date(), -30).toISOString(),
      to: dateValues[1] ? dateValues[1].toISOString() : new Date().toISOString(),
      accountId: accountValue || '',
    };
  }, [filters]);

  const [sortBy, setSortBy] = useState('mentions');
  const [page, setPage] = useState(1);
  const [summarizing, setIsSummarizing] = useState(false);
  const [summary, setSummary] = useState('');
  const [summarized, setSummarized] = useState(false);
  const { success } = useToast();

  const handleClose = () => {
    changeAccountValue('account', '');
    onClose();
    setIsSummarizing(false);
    setSummary('');
    setSummarized(false);
  };

  const { data: topAccounts, isFetching: topAccountsFetching } = useQuery({
    queryKey: [
      'topic-modal-accounts',
      topAccountsFilters.from,
      topAccountsFilters.to,
      topAccountsFilters.accountId,
      page,
      topic.id,
      sortBy,
      open,
    ],
    queryFn: async () => {
      if (!open) {
        return null;
      }

      if (!topic.id) return null;

      const options: any = [];
      if (topAccountsFilters.accountId) {
        options['accountIds'] = [topAccountsFilters.accountId];
      }

      const topAcc = await coreService.getTopAccountsByTopicId(
        topic.id,
        topAccountsFilters.from,
        topAccountsFilters.to,
        page,
        10,
        sortBy,
        options,
      );
      return topAcc;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  const tableData: TopAccountRecord[] = useMemo(() => {
    if (!topAccounts) return [];

    return topAccounts.accounts.map(acc => {
      return {
        account: {
          id: acc.id,
          name: acc.name || '',
          provider: acc.provider || '',
        },
        mentions: acc.mentions || 0,
        meetings: acc.meetings || 0,
        arr: acc.arr || 0,
        renewalDate: acc.renewalDate ? new Date(acc.renewalDate) : new Date(),
      };
    });
  }, [topAccounts]);

  const accountFilter = useMemo(() => {
    return render('account');
  }, [topAccountsFilters]);

  const summarizeTopics = async () => {
    setIsSummarizing(true);
    setSummarized(false);
    coreService
      .summarizeTopics(topic.id, topAccountsFilters.from, topAccountsFilters.to)
      .then(summarization => {
        setSummary(summarization);
      })
      .finally(() => {
        setSummarized(true);
        setIsSummarizing(false);
      });
  };

  const handleChangeSort = (sortBy: string) => {
    setSortBy(sortBy);
    setPage(1);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(summary);
    success('Summary copied to clipboard');
  };

  return (
    <Modal
      hideFooter
      width={1296}
      open={open}
      title={
        <Flex gap={8} align="center">
          {topic.custom && (
            <Tooltip title="This is a custom topic.">
              <InfoCircleOutlined style={{ width: '16px' }} />
            </Tooltip>
          )}

          <span>{topic.name}</span>
        </Flex>
      }
      onClose={handleClose}
      zIndex={999}
      headerExtraContent={
        <Flex gap={8} align="center">
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  label: (
                    <OptionalTooltipWrapper
                      value="Only owners or admins can edit topics."
                      display={!isOwner}
                    >
                      Edit
                    </OptionalTooltipWrapper>
                  ),
                  disabled: !isOwner,
                  icon: <EditOutlined />,
                  key: 'edit-topic',
                  onClick: () => {
                    if (onEditTopicClicked) {
                      onEditTopicClicked({
                        id: topic.id,
                        name: topic.name,
                        description: topic.description,
                      });
                    }
                  },
                },
                {
                  label: topic.following ? 'Unfollow' : 'Follow',
                  icon: topic.following ? <EyeInvisibleOutlined /> : <EyeOutlined />,
                  key: 'follow-topic',
                  onClick: () => {
                    if (topic.following && onUnfollowTopicClicked) {
                      onUnfollowTopicClicked(topic.id);
                    }

                    if (!topic.following && onFollowTopicClicked) {
                      onFollowTopicClicked(topic.id);
                    }
                  },
                },
              ],
            }}
          >
            <Button type="default" icon={<MoreOutlined />} />
          </Dropdown>
          <Alert
            customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
            message={
              !topAccounts?.eventsCount
                ? 'Loading information.'
                : `Insights come from ${topAccounts?.eventsCount} meeting recaps based on your applied filters and meeting access.`
            }
            variant="info"
            title=""
          />
        </Flex>
      }
    >
      <Container>
        {children}
        <Flex gap={'8px'}>
          <Select
            value={sortBy}
            onChange={value => handleChangeSort(value)}
            options={[
              { value: 'mentions', label: '# of Detections' },
              { value: 'meetings', label: '# of Meetings' },
            ]}
          />
          {render('dateRange')}
          {accountFilter}
        </Flex>
        <SummaryContainer>
          {summarized && (
            <Flex vertical gap={'8px'}>
              {' '}
              <Flex gap={'8px'}>
                <Button type="default" onClick={summarizeTopics}>
                  Summarize this topic
                </Button>
                <Button icon={<CopyOutlined />} onClick={handleCopy} />
              </Flex>
              {summary}
            </Flex>
          )}

          {!summarized && !summarizing && (
            <Button type="primary" onClick={summarizeTopics}>
              Summarize this topic
            </Button>
          )}

          {summarizing && (
            <Spin
              size="small"
              tip="Generating your summary. Closing the modal at any time will reset the summary."
              style={{
                display: 'inline-flex',
                minWidth: '600px',
                height: '100%',
                position: 'relative',
                padding: '20px',
              }}
            >
              <div />
            </Spin>
          )}
        </SummaryContainer>

        <Collapse
          defaultActiveKey={[]}
          items={[
            {
              key: '1',
              label: 'Description',
              children: (
                <Typography.Text>
                  {topic.description ?? 'Description not currently available.'}
                </Typography.Text>
              ),
            },
          ]}
        />

        <Card title="Accounts">
          <CardContents>
            <TopAccountsCondensedTable
              elems={tableData}
              loading={topAccountsFetching}
              filters={topAccountsFilters}
              sortBy={sortBy}
              topic={topic}
            />
            <Pagination
              style={{
                alignSelf: 'flex-end',
              }}
              onChange={value => setPage(value)}
              hideOnSinglePage
              showSizeChanger={false}
              total={topAccounts?.pagination?.items?.total || 1}
              pageSize={topAccounts?.pagination?.pages?.pageSize || 10}
              current={topAccounts?.pagination?.pages?.currentPage || 1}
            />
          </CardContents>
        </Card>
      </Container>
    </Modal>
  );
};

export const TopicDetailModal: React.FC<PropsWithChildren<Props>> = ({
  topic,
  open,
  onClose,
  onEditTopicClicked,
  onFollowTopicClicked,
  onUnfollowTopicClicked,
  filters,
  children,
}) => {
  return (
    <DashboardFilterProvider
      filters={{
        dateRange: {
          type: 'dateRange',
          config: {
            interval: [new Date(Date.parse(filters.from)), new Date(Date.parse(filters.to))],
            value: 'custom',
          },
        },
        account: {
          type: 'account',
          config: {
            selected: filters.accountId || '',
          },
        },
      }}
    >
      <Wrapper
        open={open}
        topic={topic}
        onClose={onClose}
        onEditTopicClicked={onEditTopicClicked}
        onFollowTopicClicked={onFollowTopicClicked}
        onUnfollowTopicClicked={onUnfollowTopicClicked}
      >
        {children}
      </Wrapper>
    </DashboardFilterProvider>
  );
};
