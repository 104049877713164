import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Checkbox, Dropdown, Input } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import Styles from './styles';
import pluralize from 'pluralize';
import { useDebouncedCallback } from 'use-debounce';
import { EllipsisWithTooltip } from '../../../../features/shared/components/EllipsisWithTooltip';

const { CustomInput, OptionsContainer, SelectAllHeader, Contents, OptionLabel, DropdownOption } =
  Styles;

interface Options {
  id: string;
  label: string;
  selected: boolean;
}

interface Props {
  label: string;
  value?: string[];
  onSelectionChanged: (values: string[]) => unknown;
  showAll?: boolean;
  style?: React.CSSProperties;
  options: { id: string; label: string }[];
}

const formatNumber = (num: string): string => {
  const number = parseFloat(num);
  if (isNaN(number)) return num;

  // format numbering system
  return number.toLocaleString('en-IN');
};

export const SelectAll: React.FC<Props> = ({
  value = [],
  showAll = false,
  options,
  label,
  style = {},
  onSelectionChanged,
}) => {
  const [currentOptions, setCurrentOptions] = useState<Options[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const newOptions: Options[] = options.map(opt => {
      return {
        ...opt,
        selected: value.includes(opt.id),
      };
    });

    setCurrentOptions(newOptions);
  }, [options, value]);

  const searchDebounce = useDebouncedCallback(value => {
    setSearchValue(value);
  }, 200);

  const filteredOptions = useMemo(() => {
    if (searchValue) {
      return currentOptions.filter(o => o.label.toLowerCase().includes(searchValue.toLowerCase()));
    }

    return currentOptions;
  }, [currentOptions, searchValue]);

  const optionsCount = currentOptions.length;
  const selectedCount = currentOptions.filter(o => o.selected).length;
  const hasOptions = optionsCount > 0;
  const allSelected = optionsCount === selectedCount;

  const handleSelectionChanged = (id: string, checked: boolean) => {
    const newValues = currentOptions
      .map(o => {
        if (o.id === id) {
          o.selected = checked;
        }
        return o;
      })
      .filter(o => o.selected)
      .map(o => o.id);

    onSelectionChanged(newValues);
  };

  const handleSelectAll = () => {
    const allOptionsId = options.map(o => o.id);
    onSelectionChanged(allSelected ? [] : allOptionsId);
  };

  const inputPlaceholder = useMemo(() => {
    if (!hasOptions) {
      return `No ${pluralize(label)} available`;
    }

    if (allSelected) {
      return `All ${pluralize(label)} selected`;
    }

    if (selectedCount > 0) {
      return `${selectedCount} ${pluralize(label, selectedCount)} selected`;
    }

    return `Select ${pluralize(label)}`;
  }, [label, hasOptions, allSelected, selectedCount]);

  return (
    <Dropdown
      dropdownRender={() => (
        <Contents>
          {showAll && (
            <SelectAllHeader>
              <OptionLabel onClick={handleSelectAll}>
                {allSelected ? 'Unselect All' : 'Select All'}
              </OptionLabel>
            </SelectAllHeader>
          )}

          <OptionsContainer>
            {filteredOptions.map(option => {
              return (
                <DropdownOption
                  onClick={() => handleSelectionChanged(option.id, !option.selected)}
                  key={option.id}
                >
                  <OptionLabel style={{ overflow: 'hidden' }}>
                    <EllipsisWithTooltip
                      text={
                        !isNaN(Number(option.label)) ? formatNumber(option.label) : option.label
                      }
                    />
                  </OptionLabel>
                  <Checkbox checked={option.selected} />
                </DropdownOption>
              );
            })}
          </OptionsContainer>
        </Contents>
      )}
      trigger={['click']}
      onOpenChange={open => setDropdownOpen(open)}
    >
      <CustomInput
        type="search"
        className={value.length ? 'has-value' : ''}
        placeholder={inputPlaceholder}
        disabled={!hasOptions}
        style={style}
        onKeyUp={e => searchDebounce(e.currentTarget.value)}
        suffix={
          !dropdownOpen ? (
            <DownOutlined
              style={{
                transform: dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.3s',
                width: '12px',
                height: '12px',
                color: 'rgba(0, 0, 0, 0.25)',
              }}
            />
          ) : (
            <SearchOutlined
              style={{
                width: '12px',
                height: '12px',
                color: 'rgba(0, 0, 0, 0.25)',
              }}
            />
          )
        }
      />
    </Dropdown>
  );
};
