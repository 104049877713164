import { AxiosInstance } from 'axios';
import { createAxiosInstance } from '../../utils/axios';
import {
  AskMeAnythingInsightArgs,
  AskMeAnythingRecapArgs,
  AskMeAnythingResult,
  FetchUserSettingsResult,
  CreateZapierApiKeyResult,
  GetInsightsAggregatedDataArgs,
  GetInsightsAggregatedDataResult,
  GetInsightsItemsArgs,
  GetInsightsItemsResult,
  GetOrganizationInfoResult,
  RequestIntegrationUrlResult,
  SummarizeItemsArgs,
  SummarizeItemsResult,
  FetchUserLibrariesApiResponse,
  CreateFolderApiResponse,
  GetFoldersItemsApiResponse,
  FetchFolderItemApiResponse,
  ChatItem,
  GetInsightCategoriesResponse,
  UpdateInsightCategoriesArgs,
  GetAccountsResponse,
  InviteBotArgs,
  UploadFileArgs,
  SummarizeInsightsArgs,
  SummarizeInsightsResult,
  ILink,
  GetCrmMappingResult,
  UpdateMappingArgs,
  FetchRecapResponse,
  UpdateEventArgs,
  AllInsightQueryData,
  TopAccountsType,
  TopMentionsType,
  TopTopicsType,
  InsightsFilters,
  Filter,
  GetAccountEventSummarizationsResponse,
  SummarizeEventsResponse,
  GetAccountActionItemsArgs,
  GetAccountActionItemsResponse,
  GetAccountTrendsResponse,
  GetWorkspaceCrmIntegrationResponse,
  UpdateWorkspaceSelectedPropertiesArgs,
  GetAccountCrmPropertiesResponse,
  GetWorkspaceCrmFiltersResponse,
  UpdateTopicArgs,
  CreateTopicArgs,
  TopicType,
} from '../types';
import { Settings } from '../../features/user/types';
import { User } from '../../redux/typings/session';
import { ContextData } from '../../pages/settings/Tabs/Organizations/components/WorkspaceContext';
import { StoredFilterType } from '../../pages/insights/components/StoredFilters/types';
import { Framework, FrameworkExecution, Question } from '../../features/frameworks/type';

class CoreService {
  constructor(private readonly axiosInstance: AxiosInstance) { }

  async getMyWorkspace() {
    return await this.axiosInstance.get(`/api/users/me/workspace`).then(({ data }) => data);
  }

  async cropPlaybackCropping(recapId: string, crop: { start: number; end: number }) {
    await this.axiosInstance.put(`/events/${recapId}/playback/cropping`, crop);
  }

  async changeMemberRole(userId: string, role: string) {
    const response = await this.axiosInstance.put(`/users/${userId}/membership/role`, {
      value: role,
    });
    return response.data;
  }

  async removeMember(userId: string) {
    const response = await this.axiosInstance.delete(`/users/${userId}/membership`);
    return response.data;
  }

  async transferOrganization(userId: string) {
    await this.axiosInstance.post(`/organizations/transfer`, {
      toUserId: userId,
    });
  }

  async summarizeItems(args: SummarizeItemsArgs) {
    const response = await this.axiosInstance.post<SummarizeItemsResult>(
      `/insights/summarize`,
      null,
      {
        params: args,
      },
    );
    return response.data;
  }

  async getInsightsAggregatedData(args: GetInsightsAggregatedDataArgs) {
    const response = await this.axiosInstance.get<GetInsightsAggregatedDataResult>(
      `/insights/aggregation`,
      {
        params: args,
      },
    );
    return response.data;
  }

  async getInsightsItems(args: GetInsightsItemsArgs) {
    const response = await this.axiosInstance.get<GetInsightsItemsResult>(`/insights/items`, {
      params: args,
    });
    return response.data;
  }

  async askMeAnythingInsight(args: AskMeAnythingInsightArgs) {
    const response = await this.axiosInstance.post<AskMeAnythingResult>(
      `/ask/insights`,
      { chat: args.chat },
      {
        params: args.filters,
      },
    );
    return response.data;
  }

  async askMeAnythingRecap(args: AskMeAnythingRecapArgs) {
    const response = await this.axiosInstance.post<AskMeAnythingResult>(`/ask/recap`, {
      chat: args.chat,
      recapId: args.recapId,
    });
    return response.data;
  }

  async askMeAnythingAccount(
    chat: ChatItem[],
    accountId: string,
    from: string | null,
    to: string | null,
  ) {
    const response = await this.axiosInstance.post<AskMeAnythingResult>(
      `/ask/accounts/${accountId}?from=${from}&to=${to}`,
      {
        chat: chat,
      },
    );
    return response.data;
  }

  async modifyMemberMeetingsVisibilityLock(userId: string, locked: boolean) {
    await this.axiosInstance.put(`/members/${userId}/share-settings/lock`, { locked });
  }

  async modifyMemberMeetingsVisibility(
    userId: string,
    args: {
      internalMeetingsVisibility: string;
      externalMeetingsVisibility: string;
    },
  ) {
    await this.axiosInstance.put(`/members/${userId}/meetings-visibility`, args);
  }

  async enableGlobalMeetingVisibility() {
    await this.axiosInstance.post(`/organizations/meeting-visibility/enable`);
  }

  async disableGlobalMeetingVisibility() {
    await this.axiosInstance.post(`/organizations/meeting-visibility/disable`);
  }

  async updateOrganizationDetails(payload: { id: string; name: string }) {
    await this.axiosInstance.patch(`/organizations/${payload.id}`, {
      name: payload.name,
    });
  }

  async modifyGlobalMeetingVisibility(args: {
    internalMeetingsVisibility: string;
    externalMeetingsVisibility: string;
  }) {
    await this.axiosInstance.put(`/organizations/meetings-visibility`, args);
  }

  async fetchOrganization(): Promise<GetOrganizationInfoResult> {
    const response = await this.axiosInstance.get<GetOrganizationInfoResult>(
      `/users/me/organization`,
    );
    return response.data;
  }

  async summarizeMeetings(accountId: string, eventIds: string[]): Promise<SummarizeEventsResponse> {
    const { data } = await this.axiosInstance.post<SummarizeEventsResponse>(
      `/accounts/${accountId}/summarize-meetings`,
      {
        eventIds,
      },
    );
    return data;
  }

  async updateDoNotRecordingList(date: string[]): Promise<void> {
    const response = await this.axiosInstance.put(`/users/me/organization/do-not-record-list`, {
      value: date,
    });
    return response.data;
  }

  async toggleAllowJoinByDomain(organizationId: string, value: boolean): Promise<void> {
    await this.axiosInstance.put(`/organizations/${organizationId}/allow-join-by-domain`, {
      value,
    });
  }

  async inviteUsers(users: { email: string; type: string; role: string }[]) {
    const requests = users.map((user) =>
      this.axiosInstance.post('/invitations', { email: user.email, role: user.role, type: user.type }),
    );
    try {
      const responses = await Promise.all(requests);
      return responses.map(response => response.data);
    } catch (error) {
      throw error;
    }
  }

  async cancelInvitation(invitationId: string) {
    await this.axiosInstance.post(`/invitations/${invitationId}/cancel`);
  }

  async resendInvitation(invitationId: string) {
    await this.axiosInstance.post(`/invitations/${invitationId}/resend`);
  }

  async changeMemberType(userId: string, membershipType: string) {
    await this.axiosInstance.put(`/users/${userId}/membership/type`, {
      value: membershipType,
    });
  }

  async updateSettings(settings: Partial<Settings>) {
    await this.axiosInstance.patch(`/users/me/settings`, { ...settings });
  }

  async updateEvent(eventId: string, args: UpdateEventArgs) {
    await this.axiosInstance.patch(`/events/${eventId}`, args);
  }

  async fetchSettings() {
    const response = await this.axiosInstance.get<FetchUserSettingsResult>(`/users/me/settings`);
    return response.data;
  }

  async createZapierApiKey() {
    const { data } = await this.axiosInstance.post<CreateZapierApiKeyResult>(`/zapier/api-keys`);
    return data;
  }

  async removeIntegration(integrationId: string) {
    await this.axiosInstance.delete(`/users/me/integrations/${integrationId}`);
  }

  async removeWorkspaceIntegration(integrationId: string) {
    await this.axiosInstance.delete(`/organizations/integrations/${integrationId}/disconnect`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async requestIntegrationUrl(type: string, params?: any) {
    const { data } = await this.axiosInstance.get<RequestIntegrationUrlResult>(
      `/users/me/integrate/${type}`,
      { params },
    );
    return data.url;
  }

  async refreshCrmIntegration(userId: string) {
    const response = await this.axiosInstance.post(`users/${userId}/integrations/sync`);
    return response.data;
  }

  async fetchUserLibraries() {
    const { data } = await this.axiosInstance.get<FetchUserLibrariesApiResponse>(
      `/library/libraries`,
    );
    return data;
  }

  async createFolder(libraryId: string, folderName: string) {
    const { data } = await this.axiosInstance.post<CreateFolderApiResponse>(
      `/library/libraries/${libraryId}/folders`,
      { folderName },
    );
    return data;
  }

  async deleteFolder(folderId: string) {
    await this.axiosInstance.delete(`/library/folders/${folderId}`);
  }

  async renameFolder(folderId: string, folderName: string) {
    await this.axiosInstance.patch(`/library/folders/${folderId}`, { folderName });
  }

  async fetchFolderItems(folderId: string) {
    const { data } = await this.axiosInstance.get<GetFoldersItemsApiResponse>(
      `/library/folders/${folderId}/items`,
    );
    return data;
  }

  async removeFolderItem(folderId: string, itemId: string) {
    await this.axiosInstance.delete(`/library/folders/${folderId}/items/${itemId}`);
  }

  async addFolderItem(folderId: string, itemId: string, type: string) {
    await this.axiosInstance.post(`/library/folders/${folderId}/items/${itemId}`, { type });
  }

  async fetchFolderItem(itemId: string) {
    const { data } = await this.axiosInstance.get<FetchFolderItemApiResponse>(
      `/folder-items/${itemId}`,
    );
    return data;
  }

  async getMicrosoftSignupUrl() {
    const params = new URLSearchParams();
    const locationParams = new URLSearchParams(window.location.search);

    const utmSource = locationParams.get('utm_source') || '';
    const utmMedium = locationParams.get('utm_medium') || '';
    const utmCampaign = locationParams.get('utm_campaign') || '';
    const utmId = locationParams.get('utm_id') || '';
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    params.append('utm_source', utmSource);
    params.append('timezone', timezone);
    params.append('utm_medium', utmMedium);
    params.append('utm_campaign', utmCampaign);
    params.append('utm_id', utmId);

    const response = await this.axiosInstance.get(`users/signup/microsoft?${params.toString()}`, {
      maxRedirects: 0,
    });
    return response.data;
  }

  async addItemsToFolder(folderId: string, itemIds: string[]) {
    await this.axiosInstance.post(`/folders/${folderId}/items`, { insightIds: itemIds });
  }

  async updateOrganizationCRMSettings(
    organizationId: string,
    newSettings: {
      pushToCrmWithPrimaryProfile: boolean;
    },
  ) {
    const { data } = await this.axiosInstance.post(
      `/organizations/${organizationId}/settings/crm`,
      {
        ...newSettings,
      },
    );

    return data;
  }

  async getInsightCategories(type: string) {
    const { data } = await this.axiosInstance.get<GetInsightCategoriesResponse>(
      `/users/me/workspace/insights/categories?type=${type}`,
    );
    return data;
  }

  async updateInsightCategories(args: UpdateInsightCategoriesArgs) {
    await this.axiosInstance.put(`/users/me/workspace/insights/categories`, args);
  }

  async generateAccountStory(accountId: string) {
    await this.axiosInstance.post(`/accounts/${accountId}/account-story/generate`);
  }

  async generateStakeholderProfile(contactId: string) {
    await this.axiosInstance.post(`/contacts/${contactId}/profile/generate`);
  }

  async getAccounts() {
    const { data } = await this.axiosInstance.get<GetAccountsResponse>(`/accounts`);
    return data;
  }

  async disconnectPrimaryProfile(organizationId: string) {
    await this.axiosInstance.delete(`/organizations/${organizationId}/crm/primary-profile`);
  }

  async inviteBot(args: InviteBotArgs) {
    await this.axiosInstance.post(`/bot/invite`, args);
  }

  async uploadRecording(args: UploadFileArgs, onProgress: (progress: number) => unknown) {
    const formData = new FormData();
    formData.append('file', args.file);
    formData.append('participantsType', args.participantsType);
    formData.append('scheduledStartDate', args.scheduledStartDate);
    formData.append('scheduledEndDate', args.scheduledEndDate);

    if (args.title) {
      formData.append('title', args.title);
    }

    await this.axiosInstance.post(`/upload`, formData, {
      onUploadProgress: progressEvent => {
        const loaded = Math.round((progressEvent.loaded / progressEvent.total) * 100);
        onProgress(loaded);
      },
    });
  }

  async getContact(id: string) {
    const { data } = await this.axiosInstance.get(`/contacts/${id}`);
    return data;
  }

  async getShareLink(id: string) {
    const { data } = await this.axiosInstance.get(`/share-link/${id}`);
    return data;
  }

  async cancelShareLink(id: string) {
    await this.axiosInstance.delete(`/share-link/${id}`);
  }

  async cancelAllShareLinks(id: string) {
    await this.axiosInstance.delete(`/share-link/event/${id}`);
  }

  async validateShareLink(args: { shareLinkId: string; email: string }) {
    await this.axiosInstance.post('/share-link/validate', args);
  }

  async createShareLink(args: {
    eventId: string;
    description: string | null;
    authorizationMechanism: string;
    expiresAt: string;
    authorizedEmails: string[];
  }) {
    return await this.axiosInstance.post('/share-link', args);
  }

  async getAllShareLinks(eventId: string): Promise<{ links: ILink[] }> {
    const { data } = await this.axiosInstance.get(`/share-link/event/${eventId}`);
    data.links.sort((a: ILink, b: ILink) => {
      return new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime();
    });

    return data;
  }

  async updateShareLink(id: string, args: { description?: string; expirationDate?: number }) {
    await this.axiosInstance.patch(`/share-link/${id}`, {
      description: args.description !== null ? args.description : undefined,
      expirationDate: args.expirationDate !== null ? args.expirationDate : undefined,
    });
  }

  async summarizeInsights(args: SummarizeInsightsArgs) {
    const { data } = await this.axiosInstance.post<SummarizeInsightsResult>(
      `/insights/summary`,
      args,
    );
    return data;
  }

  async updateUser(payload: Partial<User>) {
    const response = await this.axiosInstance.post(`/users/me/update`, payload);
    return response.data;
  }

  async fetchCheatSheet() {
    const response = await this.axiosInstance.get(`/users/me/client-cheatsheet/config`);
    return response.data;
  }

  async updateCheatSheet(body: { send: boolean; scheduleHour: number }) {
    await this.axiosInstance.put(`/users/me/client-cheatsheet/config`, body);
  }

  async fetchFavourites() {
    const response = await this.axiosInstance.get(`/cohort`);
    return response.data;
  }

  async addToFavouriteCohort(accountId: string) {
    await this.axiosInstance.post(`/cohort`, { account: accountId });
  }

  async removeFromFavouriteCohort(accountId: string) {
    await this.axiosInstance.delete(`/cohort/${accountId}`);
  }

  async getCrmMapping(workspaceId: string) {
    const { data } = await this.axiosInstance.get<GetCrmMappingResult>(
      `/workspaces/${workspaceId}/crm-properties`,
    );
    return data;
  }

  async updateCrmMapping(args: UpdateMappingArgs) {
    const { workspaceId, mapping } = args;
    const { data } = await this.axiosInstance.put<GetCrmMappingResult>(
      `/workspaces/${workspaceId}/properties/mapping`,
      { mapping },
    );
    return data;
  }

  async changePrimaryDeal(accountId: string, primaryDealId: string) {
    await this.axiosInstance.put<GetCrmMappingResult>(`/accounts/${accountId}/primary-deal`, {
      primaryDealId,
    });
  }

  async updateMeetingType(eventId: string, type: string) {
    await this.axiosInstance.put(`/events/${eventId}/type`, {
      value: type,
    });
  }

  async updateWorkspaceVisibility(eventId: string, visibility: string) {
    await this.axiosInstance.put(`/events/${eventId}/organization-visibility`, {
      value: visibility,
    });
  }

  async updatePublicVisibility(eventId: string, visibility: { [k: string]: boolean }) {
    await this.axiosInstance.put(`/events/${eventId}/public-visibility-config`, visibility);
  }

  async fetchRecap(recapId: string, secret: string) {
    const recap = await this.axiosInstance.get<FetchRecapResponse>(`/events/${recapId}`, {
      params: {
        secret,
      },
    });
    return recap.data;
  }

  async excludeRecapFromInsights(recapId: string) {
    await this.axiosInstance.post(`/recaps/${recapId}/insights/exclude`);
  }

  async excludeInsight(insightId: string) {
    await this.axiosInstance.post(`/insights/${insightId}/exclude`);
  }

  async removeInsight(insightId: string) {
    await this.axiosInstance.delete(`/insights-engine/insights/${insightId}`);
  }

  async excludeInsightFromEngine(insightId: string) {
    await this.axiosInstance.post(`/insights-engine/insights/${insightId}/exclude`);
  }

  async includeRecapFromInsights(recapId: string) {
    await this.axiosInstance.post(`/recaps/${recapId}/insights/include`);
  }

  async includeInsight(insightId: string) {
    await this.axiosInstance.post(`/insights/${insightId}/include`);
  }

  async getInsightsFilters() {
    return this.axiosInstance.get(`/accounts/filters`);
  }

  async createInsightsFilter(args: Omit<StoredFilterType, 'id'>) {
    return this.axiosInstance.post(`/accounts/filters`, { ...args });
  }

  async removeInsightsFilter(id: string) {
    return this.axiosInstance.delete(`/accounts/filters/${id}`);
  }

  async updateInsightsFilter(
    id: string,
    body: {
      name?: string;
      config?: Partial<Filter>;
    },
  ) {
    return this.axiosInstance.put(`/accounts/filters/${id}`, { ...body });
  }

  async updateCompanyContext(data: ContextData) {
    await this.axiosInstance.post(`/workspace/context`, data);
  }

  async getCompanyContext() {
    return (await this.axiosInstance.get('/workspace/context')).data;
  }

  async getSsoSignupUrl(email: string) {
    const { data } = await this.axiosInstance.post(`/auth/sso/signup/url`, { email });
    return data;
  }

  async getSsoSigninUrl(email: string) {
    const { data } = await this.axiosInstance.post(`/auth/sso/signin/url`, { email });
    return data;
  }

  async getGongCredentials(): Promise<{ id: string; canDelete: boolean }> {
    return (await this.axiosInstance.get('/gong/credentials')).data;
  }

  async getGongMeetings(cursor?: string) {
    return (
      await this.axiosInstance.get('/gong/meetings', {
        params: {
          cursor,
        },
      })
    ).data;
  }

  async getClariMeetings(attendees: string[]) {
    return (
      await this.axiosInstance.get('/api/clari/calls', {
        params: {
          attendees: attendees.join(','),
        },
      })
    ).data;
  }

  async removeGongCredentials() {
    await this.axiosInstance.delete('/gong/credentials');
  }

  async addGongCredentials(id: string, key: string) {
    await this.axiosInstance.post('/gong/credentials', {
      tokenId: id,
      token: key,
    });
  }

  async importGongCalls(ids: string[]) {
    await this.axiosInstance.post('/gong/meetings/import', {
      ids,
    });
  }

  async importClariCall(id: string) {
    await this.axiosInstance.post(`/api/clari/calls/${id}/import`);
  }

  async updateJobTitle(value: { userId: string; jobTitle: string }) {
    await this.axiosInstance.put('/workspace/job-title', value);
  }

  async getAllInsightsEngine(
    from: string,
    to: string,
    accountsSortBy: string,
    topicsSortBy: string,
    args?: Partial<InsightsFilters>,
  ): Promise<AllInsightQueryData> {
    const { data } = await this.axiosInstance.post(`/insights-engine/all/query`, {
      from: from,
      to: to,
      topTopics: {
        orderBy: topicsSortBy,
        limit: 10,
      },
      topTopicsByCategory: {
        orderBy: topicsSortBy,
        limit: 10,
      },
      topAccounts: {
        orderBy: accountsSortBy,
        limit: 10,
      },
      ...args,
    });

    const f: { [k: string]: { id: string; label: string }[] } = {};
    Object.entries(data.filters).forEach(([key, value]) => {
      const k = key as string;
      f[k] = (value as string[]).map((g: string) => ({ id: g.replace(' ', ''), label: g }));
    });
    data.filters = f;

    return data;
  }

  async getTopAccounts(
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters>,
  ): Promise<TopAccountsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/top-accounts/query`, {
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      ...args,
    });

    return data;
  }

  async getTopTopics(
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters & { insightType: string; meetingType?: string }>,
  ): Promise<TopTopicsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/top-topics/query`, {
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      insightType: args?.insightType,
      meetingType: args?.meetingType,
      ...args,
    });

    return data;
  }

  async getTopicById(topicId: string): Promise<TopicType> {
    const { data } = await this.axiosInstance.get(`/topics/${topicId}`);
    return data;
  }

  async getTopCustomTopics(
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters & { insightType: string; meetingType?: string }>,
  ): Promise<TopTopicsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/top-custom-topics/query`, {
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      insightType: args?.insightType,
      meetingType: args?.meetingType,
      ...args,
    });

    return data;
  }

  async getTopFollowedTopics(
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters & { insightType: string; meetingType?: string }>,
  ): Promise<TopTopicsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/top-followed-topics/query`, {
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      insightType: args?.insightType,
      meetingType: args?.meetingType,
      ...args,
    });

    return data;
  }

  async getTopAccountsByTopicId(
    topicId: string,
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters>,
  ): Promise<TopAccountsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/top-accounts/query`, {
      topicId: topicId,
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      ...args,
    });

    return data;
  }

  async getTopMentionsByTopicId(
    topicId: string,
    from: string,
    to: string,
    page: number,
    size: number,
    orderBy: string,
    args?: Partial<InsightsFilters>,
  ): Promise<TopMentionsType> {
    const { data } = await this.axiosInstance.post(`/insights-engine/mentions/query`, {
      topicId: topicId,
      from: from,
      to: to,
      page: page,
      orderBy: orderBy,
      limit: size,
      ...args,
    });

    return data;
  }

  async createTopic(topic: CreateTopicArgs) {
    await this.axiosInstance.post(`/topics`, topic);
  }

  async updateTopic(topicId: string, topic: UpdateTopicArgs) {
    await this.axiosInstance.patch(`/topics/${topicId}`, topic);
  }

  async followTopic(topicId: string) {
    await this.axiosInstance.post(`/topics/${topicId}/follow`);
  }

  async unfollowTopic(topicId: string) {
    await this.axiosInstance.post(`/topics/${topicId}/unfollow`);
  }

  async showActionItem(recapId: string, actionItemId: string) {
    await this.axiosInstance.post(`/events/${recapId}/action-items/${actionItemId}/show`);
  }

  async hideActionItem(recapId: string, actionItemId: string) {
    await this.axiosInstance.post(`/events/${recapId}/action-items/${actionItemId}/hide`);
  }

  async deleteAccountEventSummarization(accountId: string, summarizationId: string) {
    await this.axiosInstance.delete(
      `/accounts/${accountId}/event-summarization/${summarizationId}`,
    );
  }

  async getAccountEventSummarizations(accountId: string) {
    const { data } = await this.axiosInstance.get<GetAccountEventSummarizationsResponse>(
      `/accounts/${accountId}/event-summarizations`,
    );
    return data;
  }

  async getAccountActionItems(
    args: GetAccountActionItemsArgs,
  ): Promise<GetAccountActionItemsResponse> {
    const queryParams = new URLSearchParams();

    if (args.search) {
      queryParams.set('search', args.search);
    }

    queryParams.set('from', args.from.toISOString());
    queryParams.set('to', args.to.toISOString());
    queryParams.set('includeCompleted', String(args.includeCompleted));

    const { data } = await this.axiosInstance.get<GetAccountActionItemsResponse>(
      `/accounts/${args.accountId}/action-items?${queryParams.toString()}`,
    );
    return data;
  }

  async getAccountTrends(accountId: string) {
    const { data } = await this.axiosInstance.get<GetAccountTrendsResponse>(
      `/accounts/${accountId}/trends`,
    );
    return data;
  }

  async getWorkspaceParagonToken() {
    const { data } = await this.axiosInstance.get<{
      paragon: null | { projectId: string; token: string };
    }>(`/users/me/workspace/paragon`);
    return data;
  }

  async removeCrmIntegration() {
    await this.axiosInstance.delete(`/users/me/workspace/crm-integration`);
  }

  async getWorkspaceCrmIntegration() {
    const { data } = await this.axiosInstance.get<GetWorkspaceCrmIntegrationResponse>(
      `/users/me/workspace/crm-integration`,
    );
    return data;
  }

  async createWorkspaceCrmIntegration(provider: string) {
    await this.axiosInstance.post(`/users/me/workspace/crm-integration`, {
      provider,
    });
  }

  async getWorkspaceCrmFilters() {
    const { data } = await this.axiosInstance.get<GetWorkspaceCrmFiltersResponse>(
      'users/me/workspace/crm-integration/filters',
    );
    return data;
  }

  async updateWorkspaceCrmIntegrationSelectedProperties(
    args: UpdateWorkspaceSelectedPropertiesArgs,
  ) {
    await this.axiosInstance.put(`/users/me/workspace/crm-integration/selected-properties`, args);
  }

  async getCrmAccountProperties(accountCrmId: string) {
    const { data } = await this.axiosInstance.get<GetAccountCrmPropertiesResponse>(
      `/users/me/workspace/crm-integration/accounts/${accountCrmId}`,
    );
    return data;
  }

  async removeWorkspaceSlackIntegration(workspaceId: string) {
    await this.axiosInstance.delete(`/portal/workspaces/${workspaceId}/slack-integration`);
  }

  async requestWorkspaceSlackIntegrationUrl() {
    const { data } = await this.axiosInstance.get<string>(
      `/portal/workspace/slack-integration/authentication/url`,
    );
    return data;
  }

  async summarizeTopics(topicId: string, from: string, to: string, accountIds?: string[]) {
    const { data } = await this.axiosInstance.post(`insights-engine/topics/${topicId}/summarize`, {
      from,
      to,
      accountIds,
      page: 1,
      limit: 10000,
    });
    return data.summary;
  }

  // FRAMEWORKS
  async fetchFrameworksByWorkspaceId(workspaceId: string): Promise<Framework[]> {
    const { data } = await this.axiosInstance.get<{ frameworks: Framework[] }>(
      `framework/workspace/${workspaceId}`,
    );
    return data.frameworks;
  }

  // EVENT
  async fetchEventFrameworksByExternalId(eventId: string, frameworkId: string) {
    const { data } = await this.axiosInstance.get<FrameworkExecution | null>(
      `events/${eventId}/framework/${frameworkId}`,
    );
    return data;
  }

  async updateEventFrameworkExecution(eventId: string, framework: FrameworkExecution) {
    const { data } = await this.axiosInstance.patch<FrameworkExecution | null>(
      `events/${eventId}/framework/${framework.id}`,
      {
        values: framework.values.map(v => ({ questionId: v.questionId, answer: v.answer })),
      },
    );
    return data;
  }

  async generateEventFrameworksByExternalId(eventId: string, frameworkId: string) {
    const { data } = await this.axiosInstance.post<FrameworkExecution | null>(
      `events/${eventId}/framework/${frameworkId}/process`,
    );
    return data;
  }

  // ACCOUNT
  async fetchAccountFrameworksByExternalId(accountId: string, frameworkId: string) {
    const { data } = await this.axiosInstance.get<FrameworkExecution>(
      `accounts/${accountId}/framework/${frameworkId}`,
    );
    return data;
  }

  async updateAccountFrameworkExecution(accountId: string, framework: FrameworkExecution) {
    const { data } = await this.axiosInstance.patch<FrameworkExecution | null>(
      `accounts/${accountId}/framework/${framework.id}`,
      {
        values: framework.values.map(v => ({ questionId: v.questionId, answer: v.answer })),
      },
    );
    return data;
  }

  async generateAccountFrameworksByExternalId(
    accountId: string,
    frameworkId: string,
    context?: { from: string; to: string; meetingType?: string[] },
  ) {
    const params = new URLSearchParams();
    let url = `accounts/${accountId}/framework/${frameworkId}/process`;
    if (context) {
      params.set('from', context.from);
      params.set('to', context.to);
      if (Array.isArray(context.meetingType) && context.meetingType.length > 0) {
        params.set('meetingType', context.meetingType.join(','));
      }

      url += `?${params.toString()}`;
    }

    const { data } = await this.axiosInstance.post<FrameworkExecution>(url);
    return data;
  }

  async createNewFramework(args: { name: string; meetingType: string[]; quesitons: Question[] }) {
    const { data } = await this.axiosInstance.post('framework', {
      name: args.name,
      meetingType: args.meetingType,
      questions: args.quesitons.filter(q => !!q.value),
    });
    return data;
  }

  async updateFramework(args: {
    frameworkId: string;
    name: string;
    meetingType: string[];
    quesitons: Question[];
  }) {
    const { data } = await this.axiosInstance.patch(`framework/${args.frameworkId}`, {
      name: args.name,
      meetingType: args.meetingType,
      questions: args.quesitons.filter(q => !!q.value),
    });
    return data;
  }

  async deleteFramework(frameworkId: string) {
    await this.axiosInstance.delete(`framework/${frameworkId}`);
  }

  async changeBotAnnouncement(args: { text?: boolean; voice?: boolean }) {
    await this.axiosInstance.put(`/workspace/bot-announcement`, args);
  }

  async acceptInvitation(id: string) {
    await this.axiosInstance.post(`invitations/${id}/accept-and-merge`);
  }
}

export const coreHost = process.env.REACT_APP_API_CORE || 'http://localhost:3001';

export const coreService = new CoreService(createAxiosInstance(coreHost));
