import React from 'react';
import { Button, Dropdown, Flex, Space, Tooltip, Modal } from 'antd';
import { format } from 'date-fns';
import { AccordionCard } from '../../../AccordionCard';
import { colors } from '../../../../../../../../theme/colors';

import Styles from './styles';
import {
  DeleteOutlined,
  ExclamationCircleFilled,
  MoreOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { Magic } from '../../../../../../../shared/components/Icons/Magic';
import { Link } from 'react-router-dom';
const { MeetingDate, MeetingTitle, ItemWrapper } = Styles;

const { confirm } = Modal;

interface Props {
  id: string;
  account: {
    id: string;
    name: string;
    provider: string;
  };
  title: string;
  scheduledStartDate: Date;
  insights: {
    id: string;
    type: string;
    text: string;
    hidden: boolean;
    favorite: boolean;
  }[];
  onRemove: (id: string) => void;
  onFavorite: (id: string) => void;
  onUnfavorite: (id: string) => void;
  onExclude: (id: string) => void;
}

export const Meeting: React.FC<Props> = ({
  id,
  title,
  scheduledStartDate,
  insights,
  onRemove,
  onFavorite,
  onUnfavorite,
  onExclude,
}) => {
  return (
    <AccordionCard
      style={{ width: 'calc(100% - 24px)' }}
      open={true}
      header={
        <Space>
          <MeetingTitle>{title}</MeetingTitle>
          <MeetingDate>{format(scheduledStartDate, 'MMM dd @ hh aa')}</MeetingDate>
        </Space>
      }
    >
      <Flex vertical gap="15px">
        {insights.map(insight => (
          <Flex justify="space-between" align="center" key={insight.id}>
            <ItemWrapper>
              <div
                style={{
                  backgroundColor: colors.gray[200],
                  padding: '2px 5px',
                  borderRadius: '10px',
                  color: colors.gray[700],
                  textDecoration: 'none',
                  height: '22px',
                  width: '150px',
                  minWidth: '150px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {insight.type}
              </div>
              <div
                onClick={() => {
                  window.open(`/recap/${id}?insightId=${insight.id}`, '_blank');
                }}
                style={{ maxWidth: '75%' }}
              >
                {insight.text}
              </div>
            </ItemWrapper>
            <Flex gap="8px">
              {insight.favorite && (
                <Tooltip
                  title={
                    <span>
                      This insight has been added to your{' '}
                      <Link
                        to="/libraries?tab=my-libraries"
                        style={{ color: 'white', textDecoration: 'underline' }}
                      >
                        favorites library
                      </Link>
                      .
                    </span>
                  }
                >
                  <StarFilled style={{ color: 'gold' }} />
                </Tooltip>
              )}
              <Dropdown
                trigger={['click']}
                menu={{
                  items: [
                    {
                      label: (
                        <Tooltip title="Excluding this insight will remove it from this topic, but it will still be available in its original meeting recap.">
                          <span>Exclude from Topics</span>
                        </Tooltip>
                      ),
                      icon: <Magic style={{ fill: 'orange', width: 16, marginLeft: '-3px' }} />,
                      key: 'exclude-insight',
                      onClick: () => {
                        onExclude(insight.id);
                      },
                    },
                    {
                      label: insight.favorite ? 'Remove from Favorites' : 'Favorite',
                      icon: insight.favorite ? <StarFilled /> : <StarOutlined />,
                      key: 'favorite-insight',
                      onClick: () => {
                        if (insight.favorite) {
                          onUnfavorite(insight.id);
                        } else {
                          onFavorite(insight.id);
                        }
                      },
                    },
                    {
                      label: 'Delete from Workspace',
                      icon: <DeleteOutlined />,
                      key: 'delete-insight',
                      danger: true,
                      onClick: () => {
                        confirm({
                          title: 'Are you sure you want to delete this insight?',
                          icon: <ExclamationCircleFilled />,
                          content:
                            "Deleting an insight is an irreversible action, this can't be undone",
                          okText: 'Yes, Delete',
                          okType: 'danger',
                          cancelText: 'No',
                          onOk: () => {
                            onRemove(insight.id);
                          },
                        });
                      },
                    },
                  ],
                }}
              >
                <Button type="default" size="small" icon={<MoreOutlined />} />
              </Dropdown>
            </Flex>
          </Flex>
        ))}
      </Flex>
    </AccordionCard>
  );
};
