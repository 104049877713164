import React from 'react';
import { Container, ToggleCardContainer } from './style';
import Section from '../_Misc/Section';
import ToggleCard from './ToggleCard';
import { useUserSettings } from '../../../../features/user/hooks/useUserSettings';
import { CheatSheet } from './CheatSheet';

interface Props {
  slackIntegrationEnabled?: boolean;
  setSelectedTab: (e: string) => void;
}

const Notifications: React.FC<Props> = ({ slackIntegrationEnabled, setSelectedTab }) => {
  const { settings, updateSettings } = useUserSettings();

  const goToIntegrations = () => {
    setSelectedTab('integrations');
  };

  return (
    <Container>
      <Section
        title="Notifications config"
        description="Time to play DJ with your notification settings – mix 'em up!"
      />

      <Section title="By Email">
        <ToggleCardContainer>
          <ToggleCard
            description="Send me email notifications after each recapped meeting."
            checked={settings.receiveRecapsOnEmail}
            onChange={e => updateSettings({ receiveRecapsOnEmail: !!e })}
            recommendedSetting={'ON'}
          />

          <ToggleCard
            description="Send recap to meeting invitees who are part of my workspace"
            extraDescription="For the meetings that you are the owner of, UpdateAI will send a public view of the recap to all Workspace members that were invited to the meeting. This will be regardless of whether or not they attended the meeting."
            checked={settings.sendRecapUpdateUsers}
            onChange={e => updateSettings({ sendRecapUpdateUsers: !!e })}
            recommendedSetting={'ON'}
          />

          <ToggleCard
            description="Send recap to all meeting invitees (including external)"
            extraDescription="For the meetings that you are the owner of, UpdateAI will send a public view of the recap to all meeting invitees. This will be regardless of whether or not they attended the meeting."
            checked={settings.sendRecapToInvitees}
            onChange={e => updateSettings({ sendRecapToInvitees: !!e })}
            recommendedSetting={'OFF'}
          />

          <ToggleCard
            description='Receive Zoom’s "cloud recording now available" email notification'
            extraDescription="We recommend disabling this setting so that your meeting recap emails come from UpdateAI instead of Zoom."
            checked={settings.cloudRecordingEmail}
            onChange={e => updateSettings({ cloudRecordingEmail: !!e })}
            recommendedSetting={'OFF'}
            learnMore="https://help.update.ai/en/articles/6747031-how-do-i-turn-off-zoom-s-cloud-recording-now-available-email"
          />

          <ToggleCard
            description='Receive an alert every time I have a meeting with “limited dialogue"'
            extraDescription="If there was insufficient dialogue to generate insights, we will send you an alert."
            checked={settings.sendLimitedDialogueEmail}
            onChange={e => updateSettings({ sendLimitedDialogueEmail: !!e })}
            recommendedSetting={'OFF'}
            learnMore="https://help.update.ai/en/articles/7033173-why-wasn-t-updateai-able-to-generate-any-insights-of-my-meeting"
          />

          <CheatSheet />

          <ToggleCard
            description="Receive weekly reports for followed topics"
            extraDescription="Every Friday at 7am, you will receive a summary of the topics you follow, with the new insights over the week."
            checked={settings.sendWeeklyFollowedTopicReport}
            onChange={e => updateSettings({ sendWeeklyFollowedTopicReport: !!e })}
            recommendedSetting={'ON'}
          />
        </ToggleCardContainer>
      </Section>

      <Section title="By Slack">
        <ToggleCardContainer>
          <ToggleCard
            description="Receive meeting summaries and action items in Slack."
            checked={settings.receiveRecapsOnSlack}
            onChange={e => updateSettings({ receiveRecapsOnSlack: !!e })}
            recommendedSetting={'ON (REQUIRES SLACK INTEGRATION)'}
            onClickRecommendedSetting={goToIntegrations}
            disabled={!slackIntegrationEnabled}
            tooltipTitle="Requires Slack integration"
          />
        </ToggleCardContainer>
      </Section>
    </Container>
  );
};

export default Notifications;
