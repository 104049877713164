import React, { useMemo, useState } from 'react';
import { DashboardFilterProvider } from '../../../../../features/shared/dashboard-filters/dashboard-filters.context';
import { addDays } from 'date-fns';
import { useAccountOverview } from '../../../../../features/account/account-overview/account-overview.hook';
import { useDashboardFilters } from '../../../../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { Flex } from 'antd';
import { TopTopics } from '../../../../../features/topics/components/TopTopics';
import { TopicDetailModal } from '../../../../../features/topics/components/TopicDetailModal';
interface TopTopicsProps {
  accountId: string;
  onTopicSelected: (topic: { id: string; name: string }) => void;
  onFollowTopic: (id: string) => void;
  onUnFollowTopic: (id: string) => void;
}

const TopicsSection: React.FC<TopTopicsProps> = ({
  accountId,
  onTopicSelected,
  onFollowTopic,
  onUnFollowTopic,
}) => {
  const { filters, getDateFilterOptions, render } = useDashboardFilters();
  const [selectedTopic, setSelectedTopic] = useState<{
    id: string;
    name: string;
    description: string;
    custom: boolean;
    following: boolean;
  }>({
    id: '',
    name: '',
    description: '',
    custom: false,
    following: false,
  });

  const topAccountsFilters = useMemo(() => {
    const dateValues = getDateFilterOptions('dateRange');
    return {
      from: dateValues[0] ? dateValues[0].toISOString() : addDays(new Date(), -30).toISOString(),
      to: dateValues[1] ? dateValues[1].toISOString() : new Date().toISOString(),
      accountIds: [accountId],
    };
  }, [filters]);

  return (
    <Flex vertical gap={'16px'}>
      {render('dateRange')}
      <TopicDetailModal
        filters={topAccountsFilters}
        onClose={() =>
          setSelectedTopic({ id: '', name: '', description: '', custom: false, following: false })
        }
        open={!!selectedTopic.id}
        topic={selectedTopic}
      />
      <TopTopics
        showAccountColumn={false}
        filters={topAccountsFilters}
        onTopicSelected={setSelectedTopic}
        insightType="Risks"
        onFollowTopic={onFollowTopic}
        onUnfollowTopic={onUnFollowTopic}
      />
      <TopTopics
        showAccountColumn={false}
        filters={topAccountsFilters}
        onTopicSelected={setSelectedTopic}
        insightType="Product Feedback"
        onFollowTopic={onFollowTopic}
        onUnfollowTopic={onUnFollowTopic}
      />
      <TopTopics
        showAccountColumn={false}
        filters={topAccountsFilters}
        onTopicSelected={setSelectedTopic}
        insightType="Advocacy"
        onFollowTopic={onFollowTopic}
        onUnfollowTopic={onUnFollowTopic}
      />
      <TopTopics
        showAccountColumn={false}
        filters={topAccountsFilters}
        onTopicSelected={setSelectedTopic}
        insightType="Growth Opportunities"
        onFollowTopic={onFollowTopic}
        onUnfollowTopic={onUnFollowTopic}
      />
      <TopTopics
        showAccountColumn={false}
        filters={topAccountsFilters}
        onTopicSelected={setSelectedTopic}
        onFollowTopic={onFollowTopic}
        onUnfollowTopic={onUnFollowTopic}
      />
    </Flex>
  );
};

export const Topics: React.FC = () => {
  const { selectedAccount } = useAccountOverview();

  const dateIntervals = useMemo<[Date, Date]>(() => {
    return [addDays(new Date(), -120), new Date()];
  }, []);

  return (
    <Flex vertical gap={'16px'} style={{ overflowY: 'auto', height: 'calc(100vh - 300px)' }}>
      <DashboardFilterProvider
        filters={{
          dateRange: {
            type: 'dateRange',
            config: {
              value: 'custom',
              interval: dateIntervals,
            },
          },
        }}
      >
        <TopicsSection
          accountId={selectedAccount.id}
          onTopicSelected={() => null}
          onFollowTopic={() => null}
          onUnFollowTopic={() => null}
        />
      </DashboardFilterProvider>
    </Flex>
  );
};
