import { Button, Dropdown, Flex, Spin, Tooltip, Typography } from 'antd';
import { Table, TableColumnDefinition } from '../../../../../../componentsV2/Table';
import { TopicLabel } from '../../../TopicLabel';
import { EllipsisWithTooltip } from '../../../../../shared/components/EllipsisWithTooltip';
import { useToast } from '../../../../../../hooks/useToast';
import {
  CopyOutlined,
  EditOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { coreService } from '../../../../../../services/core/core-service';
import { TopAccountsType } from '../../../../../../services/types';
import Styles from './styles';
import { OptionalTooltipWrapper } from '../../../../../../componentsV2/OptionalTooltipWrapper';

const { TopicLabelContainer } = Styles;

export type TopAccountsItemType = {
  id: string;
  name: string;
  mentions: number;
};

export type TopTopicRecord = {
  id: string;
  name: string;
  description: string;
  mentions: number | null;
  meetings: number | null;
  accounts: number | null;
  custom: boolean;
  following: boolean;
};

interface Props {
  data: TopTopicRecord[];
  loading?: boolean;
  showAccountColumn?: boolean;
  showAccountsTooltip?: boolean;
  onTopicClicked: (args: {
    id: string;
    name: string;
    description: string;
    custom: boolean;
    following: boolean;
  }) => void;
  onEditTopicClicked?: (args: {
    id: string;
    name: string;
    description: string;
    custom: boolean;
    following: boolean;
  }) => void;
  filters?: {
    from: string;
    to: string;
    accountIds?: string[];
    crmFilters?: Record<string, string[]>;
  };
  onFollowTopicClicked: (id: string) => void;
  onUnfollowTopicClicked: (id: string) => void;
  canEditTopic?: boolean;
}

export const TopTopicsTable: React.FC<Props> = ({
  data,
  loading = false,
  onTopicClicked,
  onEditTopicClicked,
  onFollowTopicClicked,
  onUnfollowTopicClicked,
  showAccountColumn = true,
  showAccountsTooltip = false,
  filters,
  canEditTopic = false,
}) => {
  const { success } = useToast();

  const [accountsCache, setAccountsCache] = useState<Record<string, TopAccountsType>>({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async (topicId: string, page = 1) => {
    try {
      setIsLoading(true);
      const res = await coreService.getTopAccountsByTopicId(
        topicId,
        filters!.from,
        filters!.to,
        page,
        10,
        'mentions',
      );

      setAccountsCache(prev => ({
        ...prev,
        [topicId]: {
          ...res,
          accounts: [
            ...(prev[topicId]?.accounts || []).filter(
              item => !res.accounts.some(account => account.id === item.id),
            ),
            ...res.accounts,
          ],
        },
      }));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenChange = async (isVisible: boolean, record: TopTopicRecord) => {
    if (!isVisible || accountsCache[record.id] !== undefined) {
      return;
    }

    await fetchData(record.id);
  };

  const handlePageChange = async (topicId: string) => {
    const { currentPage } = accountsCache[topicId]?.pagination.pages;
    await fetchData(topicId, currentPage + 1);
  };

  const sorterFunction =
    (key: keyof TopTopicRecord) =>
    (first: TopTopicRecord, second: TopTopicRecord): number => {
      if (first[key] === null || second[key] === null) {
        return 0;
      }
      return Number(first[key]) - Number(second[key]);
    };

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text);
    success('Text copied to clipboard');
  };

  const columns: TableColumnDefinition<TopTopicRecord>[] = [
    {
      key: 'topic',
      title: 'Topics',
      width: '250px',
      render: (value, record) => {
        return (
          <TopicLabelContainer>
            {record.custom && (
              <Tooltip title="This is a custom topic created manually.">
                <InfoCircleOutlined />
              </Tooltip>
            )}
            {record.following && (
              <Tooltip title="You are following this topic.">
                <EyeOutlined />
              </Tooltip>
            )}
            <TopicLabel
              name={`${value.name}`}
              onClick={() =>
                onTopicClicked({
                  id: value.id,
                  name: value.name,
                  description: value.description,
                  custom: value.custom,
                  following: value.following,
                })
              }
            />
          </TopicLabelContainer>
        );
      },
    },
    {
      key: 'description',
      title: 'Description',
      dataIndex: 'description',
      textWrap: 'word-break',
      ellipsis: true,
      width: '600px',
      render: value => (
        <div
          style={{
            overflow: 'hidden',
          }}
        >
          {!value ? (
            <Typography.Paragraph type="secondary" style={{ marginBottom: 0 }}>
              Description not currently available.
            </Typography.Paragraph>
          ) : (
            <EllipsisWithTooltip
              text={value}
              multiline={true}
              customTooltipRender={text => (
                <Flex gap={'8px'} align="center">
                  <div>{text}</div>
                  <Tooltip title="Copy description" placement="top">
                    <Button onClick={() => handleCopyText(text)} size="small">
                      <CopyOutlined />
                    </Button>
                  </Tooltip>
                </Flex>
              )}
            />
          )}
        </div>
      ),
    },
    {
      key: 'mentions',
      title: 'Detections',
      dataIndex: 'mentions',
      width: '150px',
      sorter: sorterFunction('mentions'),
    },
    {
      key: 'meetings',
      title: 'Meetings',
      dataIndex: 'meetings',
      width: '150px',
      sorter: sorterFunction('meetings'),
    },
    {
      key: 'actions',
      title: 'Actions',
      dataIndex: 'actions',
      width: '50px',
      render(value, record) {
        return (
          <Dropdown
            trigger={['click']}
            menu={{
              items: [
                {
                  label: (
                    <OptionalTooltipWrapper
                      value="Only owners or admins can edit topics."
                      display={!canEditTopic}
                    >
                      Edit
                    </OptionalTooltipWrapper>
                  ),
                  disabled: !canEditTopic,
                  icon: <EditOutlined />,
                  key: 'edit-topic',
                  onClick: () => {
                    if (onEditTopicClicked) {
                      onEditTopicClicked({
                        id: record.id,
                        name: record.name,
                        description: record.description,
                        custom: record.custom,
                        following: record.following,
                      });
                    }
                  },
                },
                {
                  label: record.following ? 'Unfollow' : 'Follow',
                  icon: record.following ? <EyeInvisibleOutlined /> : <EyeOutlined />,
                  key: 'follow-topic',
                  onClick: () => {
                    if (record.following) {
                      onUnfollowTopicClicked(record.id);
                    } else {
                      onFollowTopicClicked(record.id);
                    }
                  },
                },
              ],
            }}
          >
            <Button type="default" icon={<MoreOutlined />} size="small" />
          </Dropdown>
        );
      },
    },
  ];

  if (showAccountColumn) {
    columns.splice(3, 0, {
      key: 'accounts',
      title: '# of Accounts',
      dataIndex: 'accounts',
      width: '170px',
      render: (value, record) => {
        if (!showAccountsTooltip) {
          return value;
        }

        return (
          <Tooltip
            overlayStyle={{ maxWidth: '500px' }}
            mouseLeaveDelay={0.2}
            title={
              <Flex align="center" vertical gap="8px" justify="center">
                {isLoading && !accountsCache[record.id] && <Spin />}

                {accountsCache[record.id] && (
                  <div style={{ flexWrap: 'wrap', wordWrap: 'break-word' }}>
                    {accountsCache[record.id]?.accounts.map(account => account.name).join(', ')}
                  </div>
                )}

                {accountsCache[record.id]?.pagination.pages.currentPage <
                  accountsCache[record.id]?.pagination.pages.total && (
                  <Button
                    onClick={() => handlePageChange(record.id)}
                    size="small"
                    loading={isLoading}
                  >
                    Load More
                  </Button>
                )}
              </Flex>
            }
            onOpenChange={visible => handleOpenChange(visible, record)}
          >
            <Typography.Text style={{ cursor: 'default' }}>{value}</Typography.Text>
          </Tooltip>
        );
      },
      sorter: sorterFunction('accounts'),
    });
  }

  return (
    <Table
      locale={{
        emptyText: loading ? `Loading top topics...` : 'No topics found',
      }}
      pagination={false}
      loading={loading}
      columns={columns}
      data={data}
    />
  );
};
