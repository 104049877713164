import { EditOutlined } from '@ant-design/icons';
import { Button, Flex, Select } from 'antd';
import { FC, useEffect, useMemo, useState } from 'react';
import { useCrmIntegration } from '../../../../features/crm-integration/hooks/use-crm-integration';
import {
  SelectedProperty,
  WorkspaceCrmProperty,
} from '../../../../features/crm-integration/crm-integration.types';
import { coreService } from '../../../../services/core/core-service';

type Props = {
  onFinishEditing: VoidFunction;
};

export const EditCrmFilters: FC<Props> = ({ onFinishEditing }) => {
  const { workspaceCrmIntegration } = useCrmIntegration();
  const [selectedProperties, setSelectedProperties] = useState<SelectedProperty[]>([]);
  const [availableProperties, setAvailableProperties] = useState<WorkspaceCrmProperty[]>([]);
  const [isSavingProperties, setIsSavingProperties] = useState(false);

  const [isEditingFilters, setIsAddingFilter] = useState(false);

  useEffect(() => {
    if (workspaceCrmIntegration && workspaceCrmIntegration.workspace) {
      setSelectedProperties(workspaceCrmIntegration.workspace.filterProperties);
      setAvailableProperties(workspaceCrmIntegration.workspace.properties);
    }
  }, [workspaceCrmIntegration]);

  const propertiesOptions = useMemo(() => {
    return availableProperties.map(property => ({ label: property.label, value: property.id }));
  }, [availableProperties]);

  const handleFilterOptionClicked = (values: string[]) => {
    const validValues = values.filter(v => availableProperties.find(p => p.id === v) !== undefined);
    setSelectedProperties(validValues);
  };

  const handleSave = async () => {
    setIsSavingProperties(true);
    await coreService.updateWorkspaceCrmIntegrationSelectedProperties({
      displayProperties: workspaceCrmIntegration!.workspace.selectedProperties,
      filterProperties: selectedProperties,
    });
    setIsSavingProperties(false);
    setIsAddingFilter(false);
    onFinishEditing();
  };

  const isPullingProperties = availableProperties.length === 0 || isSavingProperties;

  return (
    <>
      {isEditingFilters && (
        <Flex gap={'8px'}>
          <Select
            mode="tags"
            placeholder="Select CRM filters"
            loading={isPullingProperties}
            disabled={isPullingProperties}
            filterOption={(input, option) => {
              return option?.label.toLowerCase().includes(input.toLowerCase()) || false;
            }}
            onChange={value => handleFilterOptionClicked(value)}
            autoClearSearchValue={true}
            value={selectedProperties}
            options={propertiesOptions}
            style={{ minWidth: '200px' }}
          />
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
          <Button type="default" onClick={() => setIsAddingFilter(false)}>
            Cancel
          </Button>
        </Flex>
      )}
      {!isEditingFilters && (
        <Button type="link" onClick={() => setIsAddingFilter(true)}>
          <EditOutlined />
          Edit CRM Filters
        </Button>
      )}
    </>
  );
};
