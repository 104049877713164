import React from 'react';
import { TopTopics } from '../../../../features/topics/components/TopTopics';

export const TopTopicsByTypeSection: React.FC<{
  filters: {
    from: string;
    to: string;
    accountIds?: string[];
    crmFilters?: Record<string, string[]>;
  };
  onTopicSelected: (topic: {
    id: string;
    name: string;
    description: string;
    type: string;
    custom: boolean;
    following: boolean;
  }) => unknown;
  onEventCountCalculated?: (count: number) => unknown;
  onAddTopic: (category: string) => void;
  onEditTopic: (
    topic: { id: string; name: string; description: string; custom: boolean; following: boolean },
    category: string,
  ) => unknown;
  onFollowTopic: (id: string) => void;
  onUnfollowTopic: (id: string) => void;
}> = ({
  filters,
  onTopicSelected,
  onEventCountCalculated,
  onAddTopic,
  onEditTopic,
  onFollowTopic,
  onUnfollowTopic,
}) => {
  return (
    <>
      <TopTopics
        id="top-risks"
        insightType="Risks"
        filters={filters}
        onTopicSelected={onTopicSelected}
        onEventCountCalculated={onEventCountCalculated}
        onAddTopic={onAddTopic}
        onEditTopic={topic => onEditTopic(topic, 'Risks')}
        topicsType="default"
        onFollowTopic={onFollowTopic}
        onUnfollowTopic={onUnfollowTopic}
      />

      <TopTopics
        id="top-product-feedback"
        insightType="Product Feedback"
        filters={filters}
        onTopicSelected={onTopicSelected}
        onEventCountCalculated={onEventCountCalculated}
        onAddTopic={onAddTopic}
        onEditTopic={topic => onEditTopic(topic, 'Product Feedback')}
        topicsType="default"
        onFollowTopic={onFollowTopic}
        onUnfollowTopic={onUnfollowTopic}
      />

      <TopTopics
        id="top-advocacy"
        insightType="Advocacy"
        filters={filters}
        onTopicSelected={onTopicSelected}
        onEventCountCalculated={onEventCountCalculated}
        onAddTopic={onAddTopic}
        onEditTopic={topic => onEditTopic(topic, 'Advocacy')}
        topicsType="default"
        onFollowTopic={onFollowTopic}
        onUnfollowTopic={onUnfollowTopic}
      />

      <TopTopics
        id="top-growth-opportunities"
        insightType="Growth Opportunities"
        filters={filters}
        onTopicSelected={onTopicSelected}
        onEventCountCalculated={onEventCountCalculated}
        onAddTopic={onAddTopic}
        onEditTopic={topic => onEditTopic(topic, 'Growth Opportunities')}
        topicsType="default"
        onFollowTopic={onFollowTopic}
        onUnfollowTopic={onUnfollowTopic}
      />
    </>
  );
};
