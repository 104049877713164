import React, { useEffect, useMemo, useRef, useState } from 'react';
import { TitledVerticalFlexLayout } from '../../features/shared/components/VerticalFixedLayout/VerticalFixedLayout';
import { useLocationChange } from '../../features/analytics/hooks/useLocationChange';
import { Button, Flex, Menu, Space, Tabs, Tooltip } from 'antd';
import { ApiOutlined, PlusOutlined, DatabaseOutlined } from '@ant-design/icons';
import { UpgradeToPro } from '../../componentsV2/UpgradeToPro';
import { useDashboardFilters } from '../../features/shared/dashboard-filters/dashboard-filters.hooks';
import { TopTopicsByTypeSection } from './components/TopTopicByType';
import { AllInsightQueryData } from '../../services/types';
import { InsightsAskMeAnything } from '../insights/components/InsightsAskMeAnything';
import { KeyToFilterStore } from '../../features/shared/dashboard-filters/types';
import StoredFilters from '../insights/components/StoredFilters';
import useFeatures from '../../hooks/useFeatures';
import Styles from './styles';
import { TopAccounts } from '../../features/topics/components/TopAccounts';
import { addDays } from 'date-fns';
import { TopTopics } from '../../features/topics/components/TopTopics';
import { TopicDetailModal } from '../../features/topics/components/TopicDetailModal';
import { useCrmFilterValues } from '../../features/crm-integration/hooks/use-crm-filter-values';
import { useSearchParams } from 'react-router-dom';
import { EditCrmFilters } from './components/EditCrmFilters';
import { Alert } from '../../components/alerts/Alert';
import { AddTopicModal } from '../../features/topics/components/TopTopics/components/AddTopicModal';
import { useSelector } from 'react-redux';
import { Store } from '../../redux/typings/store';
import { coreService } from '../../services/core/core-service';
import { useToast } from '../../hooks/useToast';

const { Contents, FiltersGroup, Header, HeaderRow, TopTopicsScrollableContainer } = Styles;

interface Props {
  initialData: AllInsightQueryData | null;
  isCRMConnected: boolean;
  onStoreFilter(f: Partial<KeyToFilterStore>): void;
}

const workspaceSections = [
  { id: 'top-risks', label: 'Top Risks' },
  { id: 'top-product-feedback', label: 'Top Product Feedback' },
  { id: 'top-advocacy', label: 'Top Advocacy' },
  { id: 'top-growth-opportunities', label: 'Top Growth Opportunities' },
  { id: 'top-topics', label: ' Top Topics' },
  { id: 'top-accounts', label: ' Top Accounts' },
];

export const PortfolioInsightsPage: React.FC<Props> = ({ initialData, isCRMConnected }) => {
  useLocationChange();

  const { success, error } = useToast();

  const [params, setParams] = useSearchParams();
  const [eventsCount, setEventsCount] = useState<number>(0);

  const [isAddTopicModalOpen, setIsAddTopicModalOpen] = useState(false);
  const [isTopicDetailModalOpen, setIsTopicDetailModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<string>('');

  const { filterKeys: crmFiltersKeys, refetch: refetchCrmFilters } = useCrmFilterValues();

  const user = useSelector((store: Store) => store.session.user);
  const isOwner = user?.membership.role === 'OWNER' || user?.membership.role === 'ADMIN';

  const [availableContainerHeight, setAvailableContainerHeight] = useState<number>(0);
  const [activeSection, setActiveSection] = useState<string>(workspaceSections[0].id);
  const [activeTab, setActiveTab] = useState<string>('workspace-topics');
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedTopic, setSelectedTopic] = useState<{
    topic: { id: string; name: string; description: string; custom: boolean; following: boolean };
    accountId: string;
  }>({
    topic: {
      id: '',
      name: '',
      description: '',
      custom: false,
      following: false,
    },
    accountId: '',
  });
  const {
    render,
    filters,
    getDateFilterOptions,
    getAccountsFilterOptions,
    changeAccountValue,
    getListFilterOptions,
    clearFilters,
    triggerDataLoad,
  } = useDashboardFilters();

  const features = useFeatures();
  const availableFeature = features.portfolioInsights;

  const handleConnectCrm = () => {
    window.open('/settings?tab=integrations', '_blank');
  };

  const topAccountsFilters = useMemo(() => {
    const dateValues = getDateFilterOptions('dateRange');
    const accountValue = getAccountsFilterOptions('multipleAccounts');
    let crmFilters: Record<string, string[]> | undefined = undefined;

    if (crmFiltersKeys.length) {
      crmFiltersKeys.forEach(crmFilterKey => {
        const selectedValues = getListFilterOptions(`crm_${crmFilterKey}`)
          .filter(s => s.selected)
          .map(s => s.id);
        if (selectedValues.length) {
          if (!crmFilters) crmFilters = {};
          crmFilters[crmFilterKey] = selectedValues;
        }
      });
    }
    return {
      from: dateValues[0] ? dateValues[0].toISOString() : addDays(new Date(), -30).toISOString(),
      to: dateValues[1] ? dateValues[1].toISOString() : new Date().toISOString(),
      accountIds: accountValue || '',
      crmFilters,
    };
  }, [filters, crmFiltersKeys]);

  const topicDetailsModalFilters = useMemo(() => {
    if (selectedTopic.accountId) {
      return {
        accountId: selectedTopic.accountId,
        from: topAccountsFilters.from,
        to: topAccountsFilters.to,
        crmFilters: topAccountsFilters.crmFilters,
      };
    }

    return topAccountsFilters;
  }, [topAccountsFilters, selectedTopic]);

  const handleSectionParam = () => {
    if (!params.has('section')) {
      return;
    }

    const section = params.get('section')!;

    if (!section.startsWith('top')) {
      setActiveTab(section);
      return;
    }

    const innerSection = section || workspaceSections[0].id;
    if (innerSection !== activeSection) {
      setActiveSection(innerSection || workspaceSections[0].id);
      scrollToSection(innerSection || workspaceSections[0].id);
    }
  };

  const handleTopicParam = async () => {
    if (!params.has('topicId')) {
      return;
    }

    const topicId = params.get('topicId')!;
    const topic = await coreService.getTopicById(topicId);
    setSelectedTopic({
      topic: {
        custom: topic.custom,
        id: topic.id,
        description: topic.description,
        name: topic.name,
        following: false,
      },
      accountId: '',
    });
    setSelectedCategory(topic.type);
    setIsTopicDetailModalOpen(true);
  };

  useEffect(() => {
    handleSectionParam();
    handleTopicParam();
  }, [params]);

  const calculateMaxHeight = (element: HTMLElement) => {
    const rect = element.getBoundingClientRect();
    const pointX = rect.top;
    const heightFromX = window.innerHeight - pointX;

    return heightFromX;
  };

  const calculateTabsContainerHeight = () => {
    const tabsContainer = document.querySelector('.ant-tabs');
    const height = calculateMaxHeight(tabsContainer as HTMLElement);
    setAvailableContainerHeight(height - 24); // 24 is for not hitting the end of the page
  };

  const calculateContainerHeight = () => {
    if (containerRef.current) {
      const height = calculateMaxHeight(containerRef.current);
      setAvailableContainerHeight(height - 24); // 24 is for not hitting the end of the page
    }
  };

  const handleContainerScroll = () => {
    if (!containerRef.current) return;

    for (const section of workspaceSections) {
      const element = document.getElementById(section.id);
      if (element) {
        const rect = element.getBoundingClientRect();
        const containerRect = containerRef.current.getBoundingClientRect();
        const relativeTop = rect.top - containerRect.top;

        if (
          relativeTop >= 0 &&
          relativeTop < containerRef.current.offsetHeight / 2 &&
          section.id !== activeSection
        ) {
          setActiveSection(section.id);
          break;
        }
      }
    }
  };

  useEffect(() => {
    Promise.resolve(calculateTabsContainerHeight()).then(() => {
      calculateContainerHeight();
    });

    if (containerRef.current) {
      window.removeEventListener('resize', calculateContainerHeight);
      containerRef.current.addEventListener('scroll', handleContainerScroll);
    }

    return () => {
      if (containerRef.current) {
        window.removeEventListener('resize', calculateContainerHeight);
        containerRef.current.removeEventListener('scroll', handleContainerScroll);
      }
    };
  }, []);

  const scrollToSection = (id: string) => {
    const container = containerRef.current;
    const element = document.getElementById(id);

    if (container && element) {
      const containerTop = container.getBoundingClientRect().top;
      const elementTop = element.getBoundingClientRect().top;
      const offset = elementTop - containerTop;

      container.scrollTo({
        top: container.scrollTop + offset,
        behavior: 'smooth',
      });
    }
  };

  const handleMenuItemClick = (key: string) => {
    scrollToSection(key);
    const newValue = key.replace('top-topics-', '');
    params.set('section', newValue);
    setParams(params);
  };

  const handleEventCountCalculated = (count: number) => {
    if (count !== eventsCount) {
      setEventsCount(count);
    }
  };

  const handleAddTopic = (category: string) => {
    setSelectedCategory(category);
    setIsAddTopicModalOpen(true);
  };

  const handleTabChange = (key: string) => {
    if (key === activeTab) return;
    params.set('section', key === 'workspace-topics' ? workspaceSections[0].id : key);
    setParams(params);
    setActiveTab(key);
  };

  const handleUnfollowTopic = async (id: string) => {
    try {
      await coreService.unfollowTopic(id);
      success('Topic unfollowed successfully');
      triggerDataLoad();
    } catch (err) {
      error('Failed to unfollow topic');
    }
  };

  const handleFollowTopic = async (id: string) => {
    try {
      await coreService.followTopic(id);
      success('Topic followed successfully');
      triggerDataLoad();
    } catch (err) {
      error('Failed to follow topic');
    }
  };

  const WorkspaceTopics = (
    <div>
      <Menu
        style={{
          borderRadius: '6px',
          border: '1px solid #dfe2e6',
          boxShadow: '0px 20px 24px rgba(24,30,36,0.06)',
        }}
        mode="horizontal"
        selectedKeys={[activeSection]}
        onClick={e => handleMenuItemClick(e.key)}
        items={workspaceSections.map(item => ({
          label: item.label,
          key: item.id,
        }))}
      />

      <TopTopicsScrollableContainer
        ref={containerRef}
        style={{ maxHeight: `${availableContainerHeight}px` }}
      >
        <TopTopicsByTypeSection
          filters={topAccountsFilters}
          onTopicSelected={topic => {
            setSelectedTopic({ topic, accountId: '' });
            setSelectedCategory(topic.type);
            setIsTopicDetailModalOpen(true);
          }}
          onEventCountCalculated={handleEventCountCalculated}
          onAddTopic={handleAddTopic}
          onEditTopic={(topic, category) => {
            setIsAddTopicModalOpen(true);
            setSelectedTopic({ topic, accountId: '' });
            setSelectedCategory(category);
          }}
          onFollowTopic={handleFollowTopic}
          onUnfollowTopic={handleUnfollowTopic}
        />

        <TopTopics
          id="top-topics"
          filters={topAccountsFilters}
          onTopicSelected={topic => {
            setSelectedTopic({ topic, accountId: '' });
            setSelectedCategory(topic.type);
            setIsTopicDetailModalOpen(true);
          }}
          onAddTopic={() => {
            setSelectedCategory('');
            setIsAddTopicModalOpen(true);
          }}
          onEditTopic={topic => {
            setSelectedTopic({ topic, accountId: '' });
            setSelectedCategory('');
            setIsAddTopicModalOpen(true);
          }}
          onEventCountCalculated={handleEventCountCalculated}
          onFollowTopic={handleFollowTopic}
          onUnfollowTopic={handleUnfollowTopic}
        />

        <TopAccounts
          onAccountClicked={accountId => changeAccountValue('account', accountId)}
          filters={topAccountsFilters}
          onTopicClicked={(topic, accountId) => {
            setSelectedTopic({ topic: { ...topic, custom: false, following: false }, accountId });
            setIsTopicDetailModalOpen(true);
          }}
        />
      </TopTopicsScrollableContainer>
    </div>
  );

  const CustomTopics = (
    <div>
      <TopTopicsScrollableContainer style={{ maxHeight: `${availableContainerHeight}px` }}>
        <TopTopics
          id="top-custom-topics"
          customTitle="Top Custom Topics"
          topicsType="custom"
          filters={topAccountsFilters}
          onTopicSelected={topic => {
            setSelectedTopic({ topic, accountId: '' });
            setSelectedCategory(topic.type);
            setIsTopicDetailModalOpen(true);
          }}
          onAddTopic={() => {
            setSelectedCategory('');
            setIsAddTopicModalOpen(true);
          }}
          onEditTopic={topic => {
            setSelectedTopic({ topic, accountId: '' });
            setSelectedCategory('');
            setIsAddTopicModalOpen(true);
          }}
          onEventCountCalculated={handleEventCountCalculated}
          onFollowTopic={handleFollowTopic}
          onUnfollowTopic={handleUnfollowTopic}
        />
      </TopTopicsScrollableContainer>
    </div>
  );

  const FollowedTopics = (
    <div>
      <TopTopicsScrollableContainer style={{ maxHeight: `${availableContainerHeight}px` }}>
        <TopTopics
          id="top-followed-topics"
          customTitle="Top Followed Topics"
          topicsType="followed"
          filters={topAccountsFilters}
          onTopicSelected={topic => {
            setSelectedTopic({ topic, accountId: '' });
            setSelectedCategory(topic.type);
            setIsTopicDetailModalOpen(true);
          }}
          onAddTopic={() => {
            setSelectedCategory('');
            setIsAddTopicModalOpen(true);
          }}
          onEditTopic={topic => {
            setSelectedTopic({ topic, accountId: '' });
            setSelectedCategory('');
            setIsAddTopicModalOpen(true);
          }}
          onEventCountCalculated={handleEventCountCalculated}
          onFollowTopic={handleFollowTopic}
          onUnfollowTopic={handleUnfollowTopic}
        />
      </TopTopicsScrollableContainer>
    </div>
  );

  return (
    <>
      <AddTopicModal
        open={isAddTopicModalOpen}
        onClose={() => {
          if (selectedTopic.topic.id && !isTopicDetailModalOpen) {
            setSelectedTopic({
              topic: { id: '', name: '', description: '', custom: false, following: false },
              accountId: '',
            });
          }

          setIsAddTopicModalOpen(false);
        }}
        onSuccess={(type: 'create' | 'update') => {
          if (type === 'update') {
            if (activeTab === 'custom-topics') {
              triggerDataLoad('top-custom-topics');
            } else {
              triggerDataLoad();
            }
          } else {
            if (activeTab === 'workspace-topics') {
              setActiveTab('custom-topics');
              triggerDataLoad('top-custom-topics');
            }
          }
        }}
        defaultCategory={selectedCategory}
        topic={{ ...selectedTopic.topic, type: selectedCategory }}
      />

      <TitledVerticalFlexLayout
        sider={true}
        title={
          <Flex gap={'10px'} align="center">
            VoC Topic Discovery
          </Flex>
        }
        titleDescription={
          <Flex justify="space-between" gap="16px">
            <div style={{ maxWidth: '1000px' }}>
              UpdateAI uncovers themes from your workspace's external customer meetings. No setup is
              needed, but themes require 300 meetings to take shape. For privacy, you can only see
              themes from the meetings you have access to.
            </div>
            <Flex gap="8px">
              <Tooltip title={!isOwner ? 'Only owners or admins can add topics.' : ''}>
                <Button onClick={() => handleAddTopic('')} disabled={!isOwner}>
                  <PlusOutlined /> Add Topic
                </Button>
              </Tooltip>
              <InsightsAskMeAnything category="Risks" />
            </Flex>
          </Flex>
        }
      >
        <UpgradeToPro plan="business" display={!availableFeature}>
          <Contents>
            <Header>
              <HeaderRow visible>
                <FiltersGroup>
                  <Flex gap="16px" justify="space-between" align="center" style={{ width: '100%' }}>
                    <Flex gap="8px" wrap="wrap">
                      {render('dateRange')}
                      {render('multipleAccounts')}
                      {render('industry')}
                      {render('lifecycleStage')}
                      {render('arr')}
                      {render('geography')}
                      {render('revenueSegment')}
                      {render('dealStage')}
                      {render('daysUntilRenewal')}
                      {render('meetingType')}
                      {crmFiltersKeys.map(filter => render(`crm_${filter}`))}

                      {isCRMConnected && <EditCrmFilters onFinishEditing={refetchCrmFilters} />}

                      {!isCRMConnected && (
                        <Button type="link" onClick={handleConnectCrm}>
                          <Space>
                            Connect your CRM
                            <ApiOutlined />
                          </Space>
                        </Button>
                      )}

                      <StoredFilters.Actions />
                    </Flex>

                    <Flex gap="8px">
                      <Button type="default" onClick={clearFilters}>
                        Reset Filters
                      </Button>
                    </Flex>
                  </Flex>
                </FiltersGroup>
              </HeaderRow>
            </Header>

            <section>
              <Tabs
                onChange={handleTabChange}
                activeKey={activeTab}
                tabBarExtraContent={
                  <Alert
                    customIcon={<DatabaseOutlined style={{ color: '#21A6FF' }} />}
                    message={`Insights come from ${eventsCount} meeting recaps based on your applied filters and meeting access.`}
                    variant="info"
                    title=""
                  />
                }
                items={[
                  {
                    key: 'workspace-topics',
                    label: 'Workspace Topics',
                    children: WorkspaceTopics,
                  },
                  {
                    key: 'custom-topics',
                    label: 'Custom Topics',
                    children: CustomTopics,
                  },
                  {
                    key: 'followed-topics',
                    label: "Topics I'm Following",
                    children: FollowedTopics,
                  },
                ]}
              />
            </section>

            <TopicDetailModal
              filters={topicDetailsModalFilters}
              open={isTopicDetailModalOpen}
              topic={selectedTopic.topic}
              onEditTopicClicked={() => {
                setIsAddTopicModalOpen(true);
              }}
              onClose={() => {
                setSelectedTopic({
                  topic: { id: '', name: '', description: '', custom: false, following: false },
                  accountId: '',
                });
                setIsTopicDetailModalOpen(false);
              }}
              onFollowTopicClicked={topicId => {
                try {
                  handleFollowTopic(topicId);
                  setSelectedTopic(prev => ({
                    topic: {
                      ...prev.topic,
                      following: true,
                    },
                    accountId: prev.accountId,
                  }));
                } catch (err) {
                  console.log(err);
                }
              }}
              onUnfollowTopicClicked={topicId => {
                try {
                  handleUnfollowTopic(topicId);
                  setSelectedTopic(prev => ({
                    topic: {
                      ...prev.topic,
                      following: false,
                    },
                    accountId: prev.accountId,
                  }));
                } catch (err) {
                  console.log(err);
                }
              }}
            />
          </Contents>
        </UpgradeToPro>
      </TitledVerticalFlexLayout>
    </>
  );
};
